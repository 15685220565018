export const suggestionList = [
  {
    name: "Özer Erdoğan",
    title: "ALPET Otomasyon Müdürü",
    content:
      '"Akaryakıt sektöründe; kesintisiz internet bağlantısı, yasal gerekliliklerin yerine getirilmesinde ve ticaretin başarılı bir şekilde devam etmesinde büyük önem taşımaktadır. Bu yolda bizimle birlikte yürüyen İşNet’in, veri akışında kesinti yaşamadan istasyonlarımızı sorunsuz yönetebilme olanağı sunmasından son derece memnunuz."',
    logo: "/suggestion_slider/alpet-logo.jpeg",
    profileImg: "/suggestion_slider/ozer-erdogan.jpeg",
  },
  {
    name: "Hasan Bartınlı",
    title: "EREN HOLDİNG Bilgi Teknolojileri Müdür",
    content:
      "İşNet, MPLS networkümüzü güvenle emanet ettiğimiz, uzak üretim tesislerimizi bize yakınlaştıran ve aynı platformlarda çalışmamızı sağlayan bir değer bizim için. Nadir de olsa uç noktalarımızda oluşan arızaları bizlerin dönüşünü beklemeden anında gören, takip eden ve hızlıca çözüme kavuşturan organizasyon yapıları, uzun süreli birlikteliğimizde önemli rol oynuyor.",
    logo: "/suggestion_slider/eren-holding-logo.png",
    profileImg: "/suggestion_slider/hasan-bartinli.jpeg",
  },
  {
    name: "Filiz Güler",
    title: "GRUP FLORENCE NIGHTINGALE HASTANELERI Bilgi Sistemleri Direktörü",
    content:
      "Yaklaşık 15 yıldır İşNet firmasından MPLS ve internet konusunda hizmet alıyoruz. Hizmet aldığımız süre içerisinde hiçbir kesinti ve problem yaşamamış olmamız gerçekten de bizlere güven veriyor. Bundan sonraki projelerimizde de hızlı çözümleri, uzman kadrosu ve tecrübesinden dolayı “İşNet” diyoruz. Teşekkürler İşNet.",
    logo: "/suggestion_slider/florence-logo.png",
    profileImg: "/suggestion_slider/filiz-guler.png",
  },
  {
    name: "Murat Tekbaş",
    title: "GOLDEN GLOBAL YATIRIM BANKASI Grup Başkanı",
    content:
      "Bankacılık hizmetleri ve alternatif finansman yöntemleri sunmak amacıyla kurulan, tüm faaliyetlerini faizsiz finans prensiplerine uygun şekilde icra eden, ülkemizdeki ilk ve tek yatırım bankası kimliğiyle faaliyetlerine başlayan Golden Global Yatırım Bank olarak kuruluş sürecimizde, teknoloji ve altyapı alanında İşNet’ten kaliteli ve hızlı bir hizmet aldığımızı söylemek isterim. Kurulumun yanısıra sonraki süreçlerde de, çözüm odaklı yaklaşımlarıyla başarılı sonuçlar yaratan İşNet’e çok teşekkür ediyoruz. MPLS hizmetlerine ilave olarak, hala bankacılık altyapı ve teknoloji bakım&destek hizmetlerini aldığımız İşNet ile uzun yıllar birlikte, güvenle çalışacağımıza inanıyoruz.",
    logo: "/suggestion_slider/golden-global-logo.png",
    profileImg: "/suggestion_slider/murat-tekbas.jpeg",
  },
  {
    name: "FzyPay Ailesi",
    content: `<p>TCMB lisanslı elektronik para ve ödeme kuruluşu olarak hizmet veren FzyPay, sunduğu finansal çözümler ve yapay zekâ tabanlı ödeme teknolojileriyle finans sektörünün önde gelen oyuncuları arasında yer almayı hedeflemekte, sanal pos, dijital cüzdan, fatura ödeme, para transferi gibi hizmetleri ile ön plana çıkmaktadır.</p><p>Bu kapsamda İşNet’e sunucu barındırma, sunucu yönetimi, bulut santral hizmetleri başta olmak üzere verdikleri hizmetler ve çözüm odaklı yaklaşımları için teşekkür ediyoruz.</p>`,
    profileImg: "/suggestion_slider/fizypay.png",
  },
  {
    name: "Murat Seymen",
    title: "KAFA RADYO Teknik Müdürü",
    content: `Yayıncılıkta telekomünikasyon hizmeti, yaptığımız işin kalbi anlamına gelir ve İşNet ile çalışmak her şeyden önce bize güven veriyor. İşNet'in Kafa Radyo’ya ve Radyoland.com'a sağladığı geniş bant uydu internet hizmeti ile, gönül rahatlığıyla, canlı yayın araçlarımızla Türkiye'nin her yerinden, 7/24 kesintisiz olarak yayınlarınımızı gerçekleştirebiliyoruz. Teşekkürler İşNet.`,
    logo: "/suggestion_slider/kafa-radyo-logo.png",
    profileImg: "/suggestion_slider/murat-seymen.jpeg",
  },
  {
    name: "Hüseyin Uslu",
    title: "ŞENPİLİÇ Bilgi Teknolojileri Müdürü",
    content: `İşNet'in konusunda uzman, köklü bir kuruluş olması; finansal bir güç yaratmakla birlikte düzgün işleyen süreçlerle de iyi bir hizmet sunulmasını; POP noktalarının yaygın olması ise, alınan hizmetin maliyetinin uygun olmasını sağlıyor. Ayrıca iletişim altyapısının ulaştırılmasının zor olduğu lokasyonlarımızda bile İşNet'in çözüm üretebiliyor olması işimize önemli bir değer katıyor.`,
    logo: "/suggestion_slider/senpilic-logo.png",
    profileImg: "/suggestion_slider/huseyin-uslu.jpeg",
  },
];

export const featureList = [
  {
    name: "7/24 DESTEK",
    description:
      "Alanında uzman ve tecrübeli ekiplerimize her zaman ulaşma imkanı",
    img: "/information_img/support.svg",
    icon: "call",
    language: "tr",
  },
  {
    name: "81 İLDE POP NOKTASI",
    description:
      "Türkiye’nin tüm şehirlerinde yer alan POP(Point of Presence) noktalarımızla uçtan uca iletişim ve veri hizmetleri",
    img: "/information_img/pop.svg",
    icon: "pop",
    language: "tr",
  },
  {
    name: "E-DÖNÜŞÜMDE GÜVEN",
    description:
      "Sahip olduğumuz ileri teknoloji ve yedekli bulut altyapımızla sunduğumuz geniş kapsamlı e-dönüşüm hizmetleri",
    img: "/information_img/e-donusum.svg",
    icon: "edonusum",
    language: "tr",
  },
  {
    name: "DİJİTALLEŞME ÇÖZÜMLERİ",
    description:
      "Siber güvenlik, robotik süreç otomasyonu ve nesnelerin interneti gibi yeni nesil teknolojilerde güvenilir çözüm ortağı",
    img: "/information_img/digital.svg",
    icon: "digital",
    language: "tr",
  },
  {
    name: "24/7 SUPPORT",
    description:
      "Accessibility of our expert and experienced teams at all times",
    img: "/information_img/support.svg",
    icon: "call",
    language: "en",
  },
  {
    name: "POP POINTS IN 81 CITIES",
    description:
      "End-to-end communication and data services through POP (Point of Presence) located in all cities of Turkey",
    img: "/information_img/pop.svg",
    icon: "pop",
    language: "en",
  },
  {
    name: "SECURE E-TRANSFORMATION",
    description:
      "Comprehensive e-transformation services provided through our advanced technology and redundant cloud infrastructure",
    img: "/information_img/e-donusum.svg",
    icon: "edonusum",
    language: "en",
  },
  {
    name: "DIGITALIZATION SOLUTIONS",
    description:
      "Reliable solution partner for next-generation technologies such as cyber security, robotic process automation, and internet of things",
    img: "/information_img/digital.svg",
    icon: "digital",
    language: "en",
  },
];

export const sliderList = [
  {
    id: "03",
    bg: "/slider_bg/slider-dijikolay-2.png",
    mobileBg: "/slider_bg/mobile/slider-dijikolay-2.png",
    text: "DijiKolay'dan Başvurun, NetteFatura'da Yıl Sonuna Kadar Ücretsiz e-Belge Kullanmaya Hemen Başlayın!",
    detail: true,
    url: "https://www.isbank.com.tr/is-ticari/dijikolay",
    lang: "tr",
    target: true,
  },
  {
    id: "02",
    bg: "/slider_bg/slider-dijikolay-1.png",
    mobileBg: "/slider_bg/mobile/slider-dijikolay-1.png",
    text: "DijiKolay'dan Başvuran NetteFatura Müşterilerine 1 Alana 1 Bedava",
    detail: true,
    url: "https://www.isbank.com.tr/is-ticari/dijikolay",
    lang: "tr",
    target: true,
  },
  // {
  //   id: "04",
  //   bg: "/slider_bg/slider-uipath.png",
  //   mobileBg: "/slider_bg/mobile/slider-uipath.png",
  //   text: `RPA Çözümlerinde Dünya Lideri UiPath'in Platinum İş Ortağı Olduk!`,
  //   detail: true,
  //   url: "/bizdenhaberlericerik/isnet-uipath-platinum-partner-statusunu-aldi/",
  //   lang: "tr",
  // },
  // {
  //   id: "05",
  //   bg: "/slider_bg/slider-2.png",
  //   mobileBg: "/slider_bg/mobile/slider-2.png",
  //   text: `BMC Software Çözümleri, Basistek Danışmanlığıyla </br> İşNet Bulut Platformu’nda`,
  //   detail: true,
  //   url: "/bizdenhaberlericerik/bmc-software-basistek-isnet-is-ortakligi",
  //   lang: "tr",
  // },
  {
    id: "06",
    bg: "/slider_bg/yonetilen-izleme.png",
    mobileBg: "/slider_bg/mobile/yonetilen-izleme.png",
    text: `Yönetilen İzleme Çözümümüzle </br> İş Sürekliliğinizin 7/24 Takibini Yapıyoruz`,
    detail: true,
    url: "/hizmetlerimiz/veri-merkezi/yonetilen-izleme",
    lang: "tr",
  },
  // {
  //   id: "07",
  //   bg: "/slider_bg/ElBirligi_DijitalDonusumKredisi.jpeg",
  //   mobileBg: "/slider_bg/mobile/dijital-donusum-kredisi.png",
  //   text: `İş Bankası’ndan KOBİ’lere Özel Dijital Dönüşüm Kredisi Kampanyasından Yararlanın`,
  //   detail: true,
  //   url: "https://www.isbank.com.tr/kampanyalar/dijital-donusum-kredisi",
  //   lang: "tr",
  // },
  // {
  //   id: "08",
  //   bg: "/slider_bg/ssl-vpn.png",
  //   mobileBg: "/slider_bg/mobile/uzaktan-calisma.png",
  //   text: `Uzaktan Erişim (SSL VPN) Çözümümüzle Kurum Ağınıza Güvenle Bağlanın`,
  //   detail: true,
  //   url: "/hizmetlerimiz/siber-güvenlik/guvenli-erisim-ssl-vpn",
  //   lang: "tr",
  // },
  {
    id: "09",
    bg: "/slider_bg/slider-sanalsantral.png",
    mobileBg: "/slider_bg/mobile/slider-sanalsantral-mobile.png",
    text: `İşteSantral ile Şirket Hatlarınızdan Gelen Çağrıları Ofisteymiş Gibi Karşılayın, </br> İnternetin Olduğu Her Yerden Ulaşılabilir Olun`,
    detail: true,
    url: "/hizmetlerimiz/ses/istesantral",
    lang: "tr",
  },
  // {
  //   id: "10",
  //   bg: "/slider_bg/e-donusum-min.png",
  //   mobileBg: "/slider_bg/mobile/e-donusum-min.png",
  //   text: `e-Dönüşüm Süreçlerinizde, Güvenli ve Esnek Uygulamalarımızla Yanınızdayız`,
  //   detail: true,
  //   url: "/edonusum/e-fatura",
  //   lang: "tr",
  // },
  {
    id: "11",
    bg: "/slider_bg/uydu-erisimi.png",
    mobileBg: "/slider_bg/mobile/uydu-erisimi.png",
    text: `Uydu Erişimi Hizmetimizden Yararlanın, </br> Canlı Yayınlarınızı Yüksek Kalitede Gerçekleştirin`,
    detail: true,
    url: "/hizmetlerimiz/uydu-erisimi/ka-band-canli-yayin",
    lang: "tr",
  },
  {
    id: "12",
    bg: "/slider_bg/isnet-veeam-cloud-connect-slider.png",
    mobileBg: "/slider_bg/mobile/isnet-veeam-cloud-connect-slider.png",
    text: `İşNet Veeam Cloud Connect Altyapısı ile Veri Kayıplarının Önüne Geçin`,
    detail: true,
    url: "/hizmetlerimiz/veri-merkezi/veeam-bulut-yedekleme",
    lang: "tr",
  },
  // {
  //   id: "13",
  //   bg: "/slider_bg/robotik-surec-otomasyonu-isnet.png",
  //   mobileBg: "/slider_bg/mobile/robotik-surec-otomasyonu-isnet.png",
  //   text: `Robotik Süreç Otomasyonu Çözümlerimizle Verimliliği Yakalayın`,
  //   detail: true,
  //   url: "/dijitallesme/robotik-surec-otomasyonu",
  //   lang: "tr",
  // },
  {
    id: "14",
    bg: "/slider_bg/veri-guvenliginizi-kvkk-cozumlerimizle-saglayin.jpeg",
    mobileBg:
      "/slider_bg/mobile/veri-guvenliginizi-kvkk-cozumlerimizle-saglayin.png",
    text: `Kişisel Verilerin Koruması Kanunu'na Yönelik </br> Danışmanlık Hizmetlerimizden Yararlanın`,
    detail: true,
    url: "/hizmetlerimiz/siber-güvenlik/kvkk-uyumluluk-cozumleri",
    lang: "tr",
  },

  // {
  //   id: "15",
  //   bg: "/slider_bg/uzaktan-calisma.png",
  //   text: `We Offer Service, Consultancy And Infrastructure Solutions</br> Suitable For The Remote Working Model`,
  //   detail: false,
  //   url: "",
  //   lang: "en",
  // },
  {
    id: "16",
    bg: "/slider_bg/yonetilen-izleme.png",
    text: `We Monitor Your Business Continuity</br> 24/7 With Our Managed Monitoring Solution`,
    detail: true,
    url: "/en/services/data-center/managed-monitoring",
    lang: "en",
  },
  {
    id: "17",
    bg: "/slider_bg/ssl-vpn.png",
    text: `Connect Securely To Your Corporate Network With Our Remote Access Solution (SSL VPN)`,
    detail: true,
    url: "/en/services/cyber-security/secure-access-ssl-vpn",
    lang: "en",
  },
  {
    id: "18",
    bg: "/slider_bg/bulut-santral-cozumleri-isnet.png",
    text: `Receive Calls From Your Company Lines As If You Were In The Office With İştesantral`,
    detail: true,
    url: "/en/services/voice/cloud-phone-exchange",
    lang: "en",
  },
  {
    id: "19",
    bg: "/slider_bg/e-donusum-min.png",
    text: `We Offer Safe And Flexible Applications For Your e-Transformation Processes`,
    detail: true,
    url: "/en/e-transformation/e-invoice/",
    lang: "en",
  },
  {
    id: "20",
    bg: "/slider_bg/uydu-erisimi.png",
    text: `Take Advantage Of Our Satellite Access Service, Make Your Live Broadcasts In High Quality`,
    detail: true,
    url: "/en/services/satellite-access/kaband-live-broadcast",
    lang: "en",
  },
  {
    id: "21",
    bg: "/slider_bg/isnet-veeam-cloud-connect-slider.png",
    text: `Prevent Data Loss With İşNet Veeam Cloud Connection Infrastructure`,
    detail: true,
    url: "/en/services/data-center/veeam-cloud-connect",
    lang: "en",
  },
  {
    id: "22",
    bg: "/slider_bg/robotik-surec-otomasyonu-isnet.png",
    text: `Achieve Efficiency With Our Robotic Process Automation Solutions`,
    detail: true,
    url: "/en/digitalization/robotic-process-automation",
    lang: "en",
  },
  {
    id: "23",
    bg: "/slider_bg/veri-guvenliginizi-kvkk-cozumlerimizle-saglayin.jpeg",
    text: `Benefit From Our Consultancy Services For The Protection Of Personal Data`,
    detail: true,
    url: "/en/services/cyber-security/kvkk-compatibility-solutions",
    lang: "en",
  },

  // {
  //   id: "24",
  //   bg: "/slider_bg/uzaktan-calisma.png",
  //   mobileBg: "/slider_bg/mobile/uzaktan-calisma.png",
  //   text: "Uzaktan Çalışma Modeline Uygun </br> Servis, Danışmanlık  ve Altyapı Çözümlerimizle Yanınızdayız",
  //   detail: false,
  //   lang: "tr",
  // },
  {
    id: "25",
    bg: "/slider_bg/slider-bulut.png",
    mobileBg: "/slider_bg/mobile/slider-bulut.png",
    text: `Bilişim 500'de "Yılın Bulut Hizmeti" Kategori 1.'si İşNet!`,
    detail: true,
    url: "https://www.isnet.net.tr/bizdenhaberlericerik/bilisim-500-bulut-kategorisi-odulu",
    lang: "tr",
  },
];
