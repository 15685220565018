import React, { useCallback, useRef } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Image from "next/image";

const Suggestion = ({ contentList, headerText, styleSection }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className={styleSection}>
      <div className="section_sm">
        <div className="section-header">
          <h2 className="section-title">{headerText}</h2>
        </div>
        <div className="section_sm_bg">
          <Container>
            {/* <Title TitleText={headerText} /> */}
            <div className="slider-news-wrapper suggestion">
              <Swiper
                ref={sliderRef}
                slidesPerView={1}
                spaceBetween={30}
                pagination={{ clickable: true }}
                className="slider_suggestion_main"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Navigation, Pagination, Autoplay]}
                key="5"
              >
                {contentList?.map((item, index) => (
                  <SwiperSlide key={index} className="xs:m-0 mb-8">
                    <div className="slider_suggestion">
                      <div className="slider_suggestion_profile">
                        <div className="slider_suggestion_profile_img">
                          <Image
                            src={item.profileImg}
                            alt={item.name}
                            layout="fill"
                            unoptimized
                          />
                        </div>
                        <div className="w-100">
                          {item.logo ? (
                            <div className="slider_suggestion_logo">
                              <Image
                                src={item.logo}
                                alt={item.name}
                                layout="fill"
                                unoptimized
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="slider_suggestion_name">
                          {item.name}
                        </div>
                        {item.title ? (
                          <div className="slider_suggestion_title">
                            {item.title}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="slider_suggestion_desc"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      >
                        {/*  {item.content} */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="slider-navigation slider-navigation-brand">
                <div onClick={handlePrev} className="slider-navigation-prev">
                  <i className="icon-angle-left" />
                </div>
                <div onClick={handleNext} className="slider-navigation-next">
                  <i className="icon-angle-right" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="section_bg">
        <i className="icon-quotes-left left" />
        <i className="icon-quotes-right right" />
      </div>
    </div>
  );
};
export default Suggestion;
